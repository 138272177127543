/* Default styles for non-mobile devices */
.lottie-animation-index {
  height: 300px !important;
  width: 300px !important;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .lottie-animation-index {
    height: 250px !important; /* Set the desired height for mobile devices */
    width: 250px !important; /* Set the desired width for mobile devices */
  }
}
