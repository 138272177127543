.rn-btn:hover {
    background-color: blueviolet !important;
    border-color: blueviolet !important;
}

ul li:hover a {
    color: blueviolet !important;
}

/* Default styles for screens larger than mobile */
.header-area.header--static {
    padding-left: 50px;
}

/* Media query for screens up to a maximum width of the mobile breakpoint */
@media screen and (max-width: 768px) {
    .header-area.header--static {
        padding-left: 20px;
        padding-right: 20px;
    }
}