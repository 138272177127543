/* styles.css */
.social-share li a:hover {
  color: white !important;
  background-color: blueviolet !important;
  border-color: blueviolet !important;
}

.backto-top svg {
  color: blueviolet !important;
}

