.theme-gradient {
    background: linear-gradient(145deg, rgb(151, 78, 220) 0%, blueviolet 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0,0,0,0);
    display: inline-block;
}

.team .thumbnail::after {
    background: linear-gradient(to bottom, blueviolet 0, #000000 100%);
}

ul.testimonial-thumb-wrapper li .thumb::after {
    background: blueviolet;
}
