.card-color-white {
  background-color: white !important;
}

.card-color-white:hover {
  background-color: blueviolet !important;
  color: white !important;
}

.service.service__style--2::before {
  background-image: linear-gradient(to right, rgb(151, 78, 220), blueviolet) !important;
}

.service.service__style--2 .icon {
    color: blueviolet;
}

a {
    color: blueviolet;
}

.portfolio .thumbnail-inner::before {
    background-color: blueviolet;
    background-image: linear-gradient(blueviolet 10%, #000000 100%);
}

button.rn-button-style--2.btn-solid, a.rn-button-style--2.btn-solid {
    background: blueviolet;
    color: #fff;
}

button.rn-button-style--2.btn-solid, a.rn-button-style--2.btn-solid {
    border: 2px solid blueviolet;
    background: blueviolet;
    color: #fff;
}

.blog-style--1 .thumbnail a::after {
    background: linear-gradient(to bottom, blueviolet 0, #000000 100%);
}

a.rn-btn, button.rn-btn {
    border: 2px solid rgba(255,255,255,.3);;
}

.call-to-action {
    background: linear-gradient(145deg, rgb(151, 78, 220) 0%, blueviolet 100%);
}
