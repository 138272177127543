/* styles.css */
.pv-tab-button span::after {
  background-color: blueviolet !important;
}

.portfolio .image-5 {
  background-image: url(/assets/images/portfolio/portfolio-5.jpg);
}

.portfolio .image-6 {
  background-image: url(/assets/images/portfolio/portfolio-6.jpg);
}
/* 
.neon-image {
  position: relative;
  filter: grayscale(100%);
}

.neon-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(55, 255, 0, 0.6); 
  mix-blend-mode: color;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.neon-image:hover::before {
  opacity: 1;
} */
